<app-header></app-header>
<div  *ngIf="freeCardsName">{{freeCardsError}}</div>
<div class="content" *ngIf="freeCardsName === false || freeCardsError === undefined">
  <div class="subHeader">
    <br />&nbsp;<br />
    <h2 style="font-size:32px;">Kortit</h2><br>&nbsp;<br>
  </div>
         <div id="flexWrapper">
          <div class="columns">
            <div class="column">Kortin nimi</div>
            <div class="column">Vapaita numeroita</div>
            <div class="column">Lähetys</div>
          </div>
    <div class="columns" *ngFor="let oneCard of freeCardsName; let i = index">
        <div class="column" >
            <button class="button black" (click)="checkToGetCards(oneCard.customerNumber, oneCard.restaurant)"   >{{ oneCard.card_type }}
           <span *ngIf="oneCard.restaurant">-</span>
              {{ oneCard.restaurant }}</button>
        </div>
        <div class="column">{{ oneCard.cards }}</div>
        <div class="column"><button class="button" (click)="routeToCard(oneCard.customerNumber, oneCard.restaurant)" style="border: none; background: #fa7571; border: 1px solid #fa7571 !important ;">Siirry l&auml;hett&auml;m&auml;&auml;n {{ oneCard.card_type }}</button></div>
        <div class="column"><button *ngIf="" class="button" (click)="routeToMultipleReceiver(oneCard.customerNumber, oneCard.restaurant)" style="border: none; background: #fa7571; border: 1px solid #fa7571 !important ;">multipe l&auml;hett&auml;m&auml;&auml;n {{ oneCard.card_type }}</button></div>
      <br>
    </div>
  </div>
    <br>&nbsp;&nbsp;<br>
    <br>&nbsp;&nbsp;<br>
</div>
