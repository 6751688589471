import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { timer } from 'rxjs';
import { CustomService } from '../services/custom.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@Component({
  selector: 'app-multiplereceiver',
  templateUrl: './multiplereceiver.component.html',
  styleUrls: ['./multiplereceiver.component.css'],
})
export class MultiplereceiverComponent implements OnInit {
  form: FormGroup;
  selectedCard: any;
  freeCardsName: any = [];
  freeCardsCount: any = [];
  freeNumbers: any = [];
  cardDetails: any = []; // Consider defining a more specific type
  tmpurl: any = [];
  count = 0;
  emailCard;
  note;
  loggedUserEmail: any;
  restaurant;
  cardType: any;
  errorNote;
  showButton = true;


  @ViewChild('autosize') autosize?: CdkTextareaAutosize;

  constructor(
    private customService: CustomService,
    private _ngZone: NgZone,
    private router: Router
  ) {
    this.form = new FormGroup({
      receivers: new FormControl('', Validators.required),
    });
  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autosize) {
        this.autosize.resizeToFitContent(true);
      }
    });
  }

  extractEmails = (input: string): string[] => {
    const unifiedInput = input.replace(/\n/g, ',');
    const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g;
    const emails = unifiedInput.match(emailRegex);

    const uniqueEmails = emails ? Array.from(new Set(emails)) : [];

    return uniqueEmails;
  }

  onSubmit() {
    this.loggedUserEmail = localStorage.getItem('email');
    console.log('Form submitted with content:', this.form.value.receivers);
    // Handle your form submission logic here.
    const emails = this.extractEmails(this.form.value.receivers);

    const saveEmail = (email, index) => {
      this.showButton = false;
      setTimeout(() => {
        const jsonText = {
          "loggedUserEmail": this.loggedUserEmail.replace(/['"]+/g, ''),
          "email": email,
          "type": this.cardType,
          "note": `massalähetys sähköpostiin: ${email} // lähettäjä ${this.loggedUserEmail}`,
          "restaurant": this.selectedCard.restaurant,
        }
        console.log({jsonText});

        this.customService.reserveCard(jsonText)
          .subscribe(response => {
            console.log({response});
          });
      }, index * 5000); // Käyttää indeksin arvoa sekunnin välein tapahtuvan viiveen laskemiseen
    }

    emails.forEach((email, index) => saveEmail(email, index));


  }

  ngOnInit(): void {
    this.loggedUserEmail = localStorage.getItem('email');

     const loremipsum = "dsakfasdklfdsjk";
     this.freeCardsName = ['All access -kortti', 'Viihdemaailma Ilona VIP', 'NoHo -työntekijä',
    'Noho hallinto', 'Night Access Silver', 'Night Access Gold', 'Night Access Black'];
     this.loggedUserEmail = localStorage;
      this.cardDetails = [{
        name: 'NULL CARD NOT SPECIFIED',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      },
      {
        name: 'Night Access Blue',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      },
      {
        name: 'Ilona Vip Gold',
        restaurant: '',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      },
      {
        name: 'Noho hallinto',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      },
      {
        name: 'Night Access Gold',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      },
      {
        name: 'Partner',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      },
      {
        name: 'Partner Gold',
        number: 'XXXXXXXXXX',
        description: 'kuvausteksti',
        descriptionCustomer: loremipsum
      }];

      const currentUrl = window.location.href;
      const urlParams = new URLSearchParams(currentUrl);
      const restaurant = urlParams.get('restaurant');
      this.cardType = urlParams.get('ctype');
      console.log("jooopa joo ", restaurant);
       console.log("ctype ", this.cardType);
       if (currentUrl && currentUrl.includes("?type=")) {
          if (currentUrl.includes("?type=0")) {
            this.selectedCard = this.cardDetails[0];
          } else if (currentUrl.includes("?type=1")) {
            this.selectedCard = this.cardDetails[1];
          } else if (currentUrl.includes("?type=2")) {
            this.selectedCard = this.cardDetails[2];
            this.selectedCard.restaurant = restaurant;
          } else if (currentUrl.includes("?type=3")) {
            this.selectedCard = this.cardDetails[3];
          } else if (currentUrl.includes("?type=4")) {
            this.selectedCard = this.cardDetails[4];
            this.selectedCard.restaurant = restaurant;
          } else if (currentUrl.includes("?type=5")) {
            this.selectedCard = this.cardDetails[5];
          } else if (currentUrl.includes("?type=6")) {
            this.selectedCard = this.cardDetails[6];
          }
        }


    }

}
