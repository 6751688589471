import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CustomService } from '../services/custom.service';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})


export class CardComponent implements OnInit {
  selectedCard : any;
  freeCardsName : any = [];
  freeCardsCount : any = [];
  freeNumbers : any = [];
  cardDetails = {};
  tmpurl: any = [];
  count = 0;
  emailCard;
  note;
  loggedUserEmail: any;
  restaurant;
  cardType: any;
  errorNote;
  constructor(
    private customService: CustomService,
    private router: Router
  ) { }

  ngOnInit(): void {


    this.loggedUserEmail = localStorage.getItem('email');
    this.freeCardsName = ['All access -kortti', 'Viihdemaailma Ilona VIP', 'NoHo -työntekijä',
    'Noho hallinto', 'Night Access Silver', 'Night Access Gold', 'Night Access Black'];

    const loremipsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

    this.cardDetails = [{ 
      name: 'NULL CARD NOT SPECIFIED',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum
    },
    { 
      name: 'Night Access Blue',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum
    },
    { 
      name: 'Ilona Vip Gold',
      restaurant: '',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum
    },
    { 
      name: 'Noho hallinto',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum
    },
    { 
      name: 'Night Access Gold',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum   
    },
    { 
      name: 'Partner',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum    
    },
    { 
      name: 'Partner Gold',
      number: 'XXXXXXXXXX',
      description: 'kuvausteksti',
      descriptionCustomer: loremipsum
    }];

    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);
    const restaurant = urlParams.get('restaurant');
    this.cardType = urlParams.get('ctype');
    console.log("jooopa joo ", restaurant);
     console.log("ctype ", this.cardType);
   if (currentUrl && currentUrl.includes("?type=")) {
      if (currentUrl.includes("?type=0")) {
        this.selectedCard = this.cardDetails[0];
      } else if (currentUrl.includes("?type=1")) {
        this.selectedCard = this.cardDetails[1];
      } else if (currentUrl.includes("?type=2")) {
        this.selectedCard = this.cardDetails[2];
        this.selectedCard.restaurant = restaurant;
      } else if (currentUrl.includes("?type=3")) {
        this.selectedCard = this.cardDetails[3];
      } else if (currentUrl.includes("?type=4")) {
        this.selectedCard = this.cardDetails[4];
        this.selectedCard.restaurant = restaurant;
      } else if (currentUrl.includes("?type=5")) {
        this.selectedCard = this.cardDetails[5];
      } else if (currentUrl.includes("?type=6")) {
        this.selectedCard = this.cardDetails[6];
      }
    }

    //TODO: poista kovakoodattu data
    this.freeCardsCount = this.customService.getFreeCardsCountMockData();
    this.freeNumbers = this.customService.getCardNumbersMockData();
    const tmpEmail = this.loggedUserEmail.replace(/['"]+/g, '');
    this.customService.getFreeCardsCount({email: tmpEmail})
      .subscribe(response => {
        this.freeCardsCount = response;
    });
    this.customService.getCardNumbers(this.freeCardsName.indexOf(this.selectedCard.name))
      .subscribe(response => {
        this.freeNumbers = response
    });
    this.count = this.freeCardsCount[this.freeCardsName.indexOf(this.selectedCard.name)];
  }

  routeToAdd = (): void => {
    this.router.navigateByUrl('/addnumbers');
  }

  copyCardNumber = (): void => {
    if (!Number(this.selectedCard.number)) {
      this.count--;
      this.freeCardsCount[this.freeCardsName.indexOf(this.selectedCard.name)]--;
    }
    this.selectedCard.number = this.freeNumbers[0];

    //TODO: poista kovakoodattu data
    this.freeNumbers.shift()
    this.customService.editCardNumbersMockData(this.freeNumbers);
    navigator.clipboard.writeText(this.freeNumbers[0]);
  }

  saveCard = (emailcard, note): void => {
    console.log("loggedUserEmail:", this.loggedUserEmail);
    console.log("emailCard", this.emailCard)
    console.log("restaurant", this.selectedCard.restaurant)
    const jsonText = {
      "loggedUserEmail": this.loggedUserEmail.replace(/['"]+/g, ''),
      "email": this.emailCard,
      "type": this.cardType,
      "note": this.note,
      "restaurant": this.selectedCard.restaurant,
    }

    const atpos = this.emailCard?.indexOf("@");
    const dotpos = this.emailCard?.lastIndexOf(".");


    if(validateEmail(emailcard)){
      this.customService.reserveCard(jsonText)
        .subscribe(response => {
          if (response['success']) {
            this.router.navigateByUrl('/management');
          }
          console.log({response});
      });
    } else {
      console.log("ei tainnu sähköpostia olla...");
      this.errorNote = "ei ole toimiva sähköpostiosoite"
    }
    console.log({jsonText})
  }
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
